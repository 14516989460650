import React, { useState } from 'react';
import './StudentAmbassador.css';

function StudentAmbassador() {
  const [activeSection, setActiveSection] = useState('overview');

  const sections = {
    overview: (
      <div>
        <h2 className="section-title">Program Overview</h2>
        <p>Welcome to the Floct Student Ambassador Program! Floct is a dynamic platform that connects people through shared real-world spaces, with a special focus on college communities - think Bumble BFF but only within people in the same dorm, college club, or visit the same fitness classes.</p>
        <p>As a Student Ambassador, you will play a critical role in expanding Floct's reach by onboarding student organizations, clubs, and other groups to our app. This program is designed to provide you with hands-on experience in marketing, community engagement, and business development, while also offering valuable networking opportunities and professional growth.</p>
        <h3 className="sub-section-title">Program Objectives:</h3>
        <ul className="objectives-list">
          <li>Empower students to lead the growth of Floct within their own campus communities.</li>
          <li>Develop students' skills in marketing, communication, and relationship management.</li>
          <li>Offer students practical experience in business development and customer acquisition.</li>
          <li>Provide students with networking opportunities and the chance to build a portfolio of accomplishments.</li>
        </ul>
      </div>
    ),
    learning: (
      <div>
        <h2 className="section-title">Learning Plan</h2>
        <h3 className="sub-section-title">Learning Objectives:</h3>
        <ul className="objectives-list">
          <li>Understand the key features and benefits of the Floct app and how it can serve student organizations.</li>
          <li>Develop effective communication and marketing strategies to onboard organizations to Floct.</li>
          <li>Gain experience in relationship management, business development, and customer acquisition.</li>
          <li>Learn how to leverage digital tools and social media to promote Floct within their campus communities.</li>
        </ul>
        <h3 className="sub-section-title">Program Structure:</h3>
        <ul className="structure-list">
          <li>
            <strong>Orientation and Training:</strong>
            <ul className="nested-list">
              <li>Introduction to Floct: Learn about the app, its mission, and its value proposition for student organizations.</li>
              <li>Onboarding Process: Step-by-step training on how to onboard organizations to Floct, including how to present the app, manage objections, and close deals.</li>
              <li>Marketing Strategies: Workshops and group sessions on creating effective outreach campaigns, leveraging social media, and using promotional materials.</li>
            </ul>
          </li>
          <li>
            <strong>Ambassador Activities:</strong>
            <ul className="nested-list">
              <li>Outreach: Ambassadors will be responsible for reaching out to student organizations, clubs, and campus groups to introduce Floct and explain its benefits.</li>
              <li>Onboarding: Facilitate the onboarding process for organizations, ensuring they understand how to use the app and its features.</li>
              <li>Promotion: Ambassadors will create and execute promotional campaigns, both online and offline, to increase app adoption within their campus community.</li>
            </ul>
          </li>
          <li>
            <strong>Mentorship and Support:</strong>
            <ul className="nested-list">
              <li>Assigned Mentor: Each Ambassador will be paired with a Floct team member who will provide guidance and support throughout the program.</li>
              <li>Bi-weekly Check-ins: Regular check-ins with mentors to discuss progress, challenges, and strategies for success.</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
    development: (
      <div>
        <h2 className="section-title">Professional Development</h2>
        <h3 className="sub-section-title">Networking Opportunities:</h3>
        <p>Connect with other Ambassadors across different campuses and build a network of peers in the tech and startup space.</p>
        <h3 className="sub-section-title">Career Guidance:</h3>
        <p>Receive career advice and mentorship from the Floct team, including guidance on how to leverage your Ambassador experience for future job opportunities.</p>
        <h3 className="sub-section-title">Recognition and Rewards:</h3>
        <ul className="objectives-list">
          <li>Top-performing Ambassadors will receive special recognition, including certificates, LinkedIn endorsements, and the opportunity for a letter of recommendation from the Floct team.</li>
          <li>Earn rewards such as gift cards, Floct merchandise, and other incentives based on your achievements in the program.</li>
        </ul>
      </div>
    ),
    logistics: (
      <div>
        <h2 className="section-title">Program Logistics</h2>
        <h3 className="sub-section-title">Program Duration:</h3>
        <p>The program runs each semester, and you can re-enroll as and when you like!</p>
        <h3 className="sub-section-title">Time Commitment:</h3>
        <p>The time commitment may vary, but on average, it may require a few hours per week. We understand that you have academic commitments, and we will work with you to find a balance.</p>
        <h3 className="sub-section-title">Compensation:</h3>
        <p>While this position may not be paid, we offer various incentives, such as certificates of recognition, mentorship, and valuable experience for your resume.</p>
        <p>For each organization with 15+ members you onboard, as a token of appreciation, we will be giving you a gift card worth $30 at an eatery of your choice.</p>
        <h3 className="sub-section-title">Location:</h3>
        <p>The program is primarily remote, but Ambassadors are encouraged to engage with their campus community in person where possible.</p>
      </div>
    ),
    application: (
      <div>
        <h2 className="section-title">Application Process</h2>
        <h3 className="sub-section-title">Eligibility:</h3>
        <p>Open to students currently enrolled as an undergrad in a university in North America. Must have strong communication skills, a passion for technology and social networking, and a desire to make an impact on campus.</p>
        <h3 className="sub-section-title">How to Apply:</h3>
        <p>Interested students can apply through <a href="https://forms.gle/DdGTN4G2zAVE9vvY7" className="application-link">this application link</a>.</p>
      </div>
    )
  };

  return (
    <div className="ambassador-container">
      <div className="sidebar">
        <ul className="section-menu">
          <li onClick={() => setActiveSection('overview')} className={activeSection === 'overview' ? 'active' : ''}>Program Overview</li>
          <li onClick={() => setActiveSection('learning')} className={activeSection === 'learning' ? 'active' : ''}>Learning Plan</li>
          <li onClick={() => setActiveSection('development')} className={activeSection === 'development' ? 'active' : ''}>Professional Development</li>
          <li onClick={() => setActiveSection('logistics')} className={activeSection === 'logistics' ? 'active' : ''}>Program Logistics</li>
          <li onClick={() => setActiveSection('application')} className={activeSection === 'application' ? 'active' : ''}>Application Process</li>
        </ul>
      </div>
      <div className="content">
        {sections[activeSection]}
      </div>
    </div>
  );
}

export default StudentAmbassador;
