import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Intro from './components/Intro';
import HeroSection from './components/HeroSection';
import HowItWorks from './components/HowItWorks';
import Download from './components/Download';
import Header from './components/Header';
import Footer from './components/Footer';
import CollegePartner from './components/CollegePartner';
import BusinessPartner from './components/BusinessPartner';
import Partner from './components/Partner';
import Careers from './components/Careers';
import StudentAmbassador from './components/StudentAmbassador';

function App() {
  return (
    <Router>
      <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <Intro />
              <HowItWorks />
              <Download />
              <Footer />
            </>
          } />
          <Route path="/partner" element={<Partner />} />
          <Route path="/business-partner" element={<BusinessPartner />} />
          <Route path="/college-partner" element={<CollegePartner />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers/student-ambassador" element={<StudentAmbassador />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
