import React from 'react';
import './Partner.css';

function BusinessPartner() {
  return (
    <div className="partner-container">
      <h1 className="partner-title">Partner with Floct: Business Partners</h1>
      <p className="partner-intro">
        Floct connects your business directly with the student community, helping you increase visibility, drive foot traffic, and engage with a highly targeted audience. Whether you run a café, gym, or any other local business, Floct offers unique opportunities to grow your business.
      </p>

      <div className="partner-section">
        <h2 className="section-title">Benefits for Business Partners</h2>
        <ul className="benefits-list">
          <li><strong>Targeted Visibility:</strong> Showcase your brand to students who are actively looking for places to eat, work out, and socialize.</li>
          <li><strong>Exclusive Promotions:</strong> Run special +1 offers and discounts exclusively for Floct users who meet someone else on our app, driving engagement and sales.</li>
          <li><strong>Event Hosting:</strong> Collaborate with Floct to host events at your location, increasing foot traffic and building brand loyalty.</li>
          <li><strong>Customer Insights:</strong> Access valuable data on student preferences and behavior, allowing you to tailor your offerings and improve customer satisfaction.</li>
        </ul>
      </div>

      <div className="partner-section">
        <h2 className="section-title">How to Get Started</h2>
        <p className="section-description">Becoming a Floct business partner is easy. Here’s how:</p>
        <ol className="steps-list">
          <li><strong>Contact Us:</strong> Reach out to us via email or phone to express your interest in partnering.</li>
          <li><strong>Onboarding:</strong> We’ll provide all the tools and support you need to start connecting with students through Floct.</li>
          <li><strong>Launch:</strong> Begin promoting your business, events, and offers through Floct and watch your customer base grow.</li>
        </ol>
      </div>

      <div className="contact-info">
        <h3 className="contact-title">Get in Touch:</h3>
        <p>We’re excited to help you grow your business. If you’re ready to partner with Floct or have any questions, reach out to us today!</p>
        <p>Email: <a href="mailto:shreya@floct.app" className="contact-link">shreya@floct.app</a></p>
        <p>Phone: <a href="tel:+1 (607) 379 4827" className="contact-link">+1 (607) 379 4827</a></p>
      </div>
    </div>
  );
}

export default BusinessPartner;
