import React from 'react';
import './Partner.css';

function CollegePartner() {
  return (
    <div className="partner-container">
      <h1 className="partner-title">Partner with Floct: College Partners</h1>
      <p className="partner-intro">
        Floct is the ideal platform for college clubs, dorms, and student organizations looking to connect with their members, promote events, and foster a vibrant campus community. Partner with Floct to enhance student engagement and build a strong, active network.
      </p>

      <div className="partner-section">
        <h2 className="section-title">Benefits for College Partners</h2>
        <ul className="benefits-list">
          <li><strong>Community Building:</strong> Foster a sense of community within your dorm or organization by connecting students with shared interests.</li>
          <li><strong>Member Engagement:</strong> Keep your members informed and engaged with updates, event reminders, and opportunities to get involved.</li>
        </ul>
      </div>

      <div className="partner-section">
        <h2 className="section-title">How to Get Started</h2>
        <p className="section-description">Partnering with Floct is simple. Here’s how:</p>
        <ol className="steps-list">
          <li><strong>Reach Out:</strong> Contact us via email or phone to express your interest in partnering.</li>
          <li><strong>Onboarding:</strong> We’ll provide all the tools and support you need to start connecting with students through Floct.</li>
          <li><strong>Launch:</strong> Begin promoting your events, activities, and opportunities through Floct and watch your community grow.</li>
        </ol>
      </div>

      <div className="contact-info">
        <h3 className="contact-title">Get in Touch:</h3>
        <p>We’re here to help your organization thrive. If you’re ready to partner with Floct or have any questions, reach out to us today!</p>
        <p>Email: <a href="mailto:shreya@floct.app" className="contact-link">shreya@floct.app</a></p>
        <p>Phone: <a href="tel:+1 (607) 379 4827" className="contact-link">+1 (607) 379 4827</a></p>
      </div>
    </div>
  );
}

export default CollegePartner;
