import React from 'react';
import './Intro.css';

const Intro = () => {
    return (
        <section id="introduction" className="introduction-section">
            <div className="intro-content">
                <h2>
                    Floct connects you with others who visit the same local spots as you.
                </h2>
                <br/>
                <p>Discover a new way to connect with people and support your favorite local groups and businesses with Floct. Every week, you’ll get 4 curated matches who love the same local places you do!</p>

            </div>
        </section>
    );
};

export default Intro;
