import React from 'react';
import { Link } from 'react-router-dom';
import './Careers.css';

function Careers() {
  return (
    <div className="careers-container">
      <h1 className="careers-title">Careers at Floct</h1>
      <p className="careers-intro">Explore exciting opportunities to grow with Floct.</p>
      
      <div className="career-section">
        <h2 className="section-title">Student Ambassador Program</h2>
        <p className="section-description">Become a student ambassador and help us expand Floct at your campus!</p>
        <Link to="/careers/student-ambassador">
          <button className="learn-more-button">Learn More</button>
        </Link>
      </div>
    </div>
  );
}

export default Careers;
