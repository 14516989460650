import React from 'react';
import { Link } from 'react-router-dom';
import './Partner.css';

function Partner() {
  return (
    <div className="partner-container">
      <h1 className="partner-title">Partner with Floct</h1>
      <p className="partner-intro">
        Floct is your gateway to connecting with a vibrant student community. Whether you're a local business or a college organization, we have tailored partnership opportunities designed to help you engage, grow, and succeed.
      </p>

      <div className="partner-section">
        <h2 className="section-title">Who Are You?</h2>
        <p className="section-description">
          Choose your partnership path to learn more about how Floct can help you achieve your goals:
        </p>
        <div className="partner-types">
          <div className="partner-type">
            <h3>Business Partners</h3>
            <p>If you're a café, gym, or local business, explore how Floct can help you reach more students, drive foot traffic, and increase sales.</p>
            <Link to="/business-partner">
              <button className="learn-more-button">Learn More</button>
            </Link>
          </div>
          <div className="partner-type">
            <h3>College Partners</h3>
            <p>If you're a college club, dorm, or student organization, discover how Floct can help you connect with members, promote events, and build a stronger community.</p>
            <Link to="/college-partner">
              <button className="learn-more-button">Learn More</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <h3 className="contact-title">Get in Touch:</h3>
        <p>If you're interested in partnering with Floct or have any questions, we'd love to hear from you.</p>
        <p>Email: <a href="mailto:shreya@floct.app" className="contact-link">shreya@floct.app</a></p>
        <p>Phone: <a href="tel:+1 (607) 379 4827" className="contact-link">+1 (607) 379 4827</a></p>
      </div>
    </div>
  );
}

export default Partner;
