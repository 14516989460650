import React from 'react';
import './Footer.css';
import appleStoreLogo from '../assets/images/apple-store-logo.svg';  // Ensure the image is placed in the correct directory
import googlePlayLogo from '../assets/images/google-store-logo.svg';  // Ensure the image is placed in the correct directory
import instagramIcon from '../assets/images/instagram-icon.png';  // Ensure the image is placed in the correct directory
import mailIcon from '../assets/images/mail-icon.png';  // Ensure the image is placed in the correct directory

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Floct. All rights reserved.</p>

        <div className="social-links">

        </div>
      </div>
    </footer>
  );
};

export default Footer;
