import React from 'react';
import './HeroSection.css';
import heroImage from '../assets/images/hero-image-qr-cafe.png';  // Ensure the image is placed in the correct directory
import {handleAppleStoreClick, handleGooglePlayClick} from "./Analytics.js"
import appleStoreLogo from '../assets/images/apple-store-logo.svg';  // Ensure the image is placed in the correct directory
import googlePlayLogo from '../assets/images/google-store-logo.svg';  // Ensure the image is placed in the correct directory
import appLogo from '../assets/images/logo.png';  // Ensure the image is placed in the correct directory

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="parallax" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-overlay">
          <div className="hero-content">
            <img src={appLogo} alt="App Logo" className="app-logo" />
            <h1 className="main-heading">Meet People You'll Love</h1>
            <h1 className="sub-heading">...from places you already love visiting</h1>
            <div className="download-buttons">
              <a href="#" className="download-link" onClick={handleAppleStoreClick}>
                <img src={appleStoreLogo} alt="Download on the Apple Store" className="download-store-logo" />
              </a>
              <a href="#" className="download-link coming-soon" onClick={handleGooglePlayClick}>
                <img src={googlePlayLogo} alt="Coming Soon on Google Play" className="download-store-logo" />
                <span className="coming-soon-text">Coming Soon</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
