import { track } from '@vercel/analytics';

export const handleAppleStoreClick = () => {
    track('apple_store_click');
    window.location.href = 'https://apps.apple.com/us/app/floct/id6480236367';
};

export const handleGooglePlayClick = () => {
    track('google_play_click');
    // Google Play link goes here when available
};