import React from 'react';
import './Download.css';
import {handleAppleStoreClick, handleGooglePlayClick} from "./Analytics.js"
import appleStoreLogo from '../assets/images/apple-store-logo.svg';  // Ensure the image is placed in the correct directory
import googlePlayLogo from '../assets/images/google-store-logo.svg';  // Ensure the image is placed in the correct directory

const Download = () => {
    return (
        <section className="download">
            <div className="download-content">
                <div className="download-links">
                    <a href="#" className="download-link" onClick={handleAppleStoreClick}>
                        <img src={appleStoreLogo} alt="Download on the Apple Store" className="download-store-logo" />
                    </a>
                    <a href="#" className="download-link coming-soon" onClick={handleGooglePlayClick}>
                        <img src={googlePlayLogo} alt="Coming Soon on Google Play" className="download-store-logo" />
                        <span className="coming-soon-text">Coming Soon</span>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Download;
