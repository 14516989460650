import React, { useEffect } from 'react';
import './HowItWorks.css';
import scanQRCodeImage from '../assets/images/scan-qr-code.png';  // Screenshot of scanning QR code
import getMatchesImage from '../assets/images/get-matches.png';  // Screenshot of getting matches
import talkMeetImage from '../assets/images/talk-meet.png';  // Screenshot of chatting and meeting

const HowItWorks = () => {

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.step');
            const windowHeight = window.innerHeight;
            elements.forEach(element => {
                const elementTop = element.getBoundingClientRect().top;
                if (elementTop < windowHeight * 0.75) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="how-it-works">
            <h2>How It Works</h2>
            <br/><br/>

            <div className="steps">
                <div className="step">
                    <img src={scanQRCodeImage} alt="Scan QR Code" className="step-image" />
                    <div className="step-text">
                        <h3>Scan QR Code at Partner Spaces</h3>
                        <p>Open the Floct app and scan the QR code at your favorite places to connect with others who visit the same spaces.</p>
                        <p>
                        P.S.: Want your favorite spot to become a floct hub so you can meet others visiting? Let us know by filling out <a href="https://airtable.com/appob3q0i8skA2O3W/shraM5GTHXFbkCH9m">this</a> form!
                        </p>
                    </div>
                </div>
                <div className="arrow-container">
                    <div className="arrow-dot-1"></div>
                    <div className="arrow-dot-2"></div>
                    <div className="arrow-head"></div>
                </div>
                <div className="step">
                <img src={getMatchesImage} alt="Get 4 Matches Every Week" className="step-image" />

                    <div className="step-text">

                        <h3>Get 4 Matches Every Week From Flocts You've Joined</h3>
                        
                        <p>Receive four personalized matches each week based on the places you visit. Our algorithm ensures you meet people with similar interests.</p>
                    </div>

                </div>
                <div className="arrow-container">
                    <div className="arrow-dot-1"></div>
                    <div className="arrow-dot-2"></div>
                    <div className="arrow-head"></div>
                </div>
                <div className="step">
                    <img src={talkMeetImage} alt="Talk and Meet" className="step-image" />
                    <div className="step-text">
                        <h3>Talk & Meet</h3>
                        <p>Chat with your matches through the app and arrange to meet in real life. Turn your online connections into real-world friendships.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
